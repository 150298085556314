.input-group {
  width: 240px;
  margin: 0 auto;
  margin-bottom: 10px;

  label {
    display: block;
    font-family: GothamPro;
    font-weight: 600;

    font-size: 12px;
    color: change-color(#111111, $alpha: 0.7);
    letter-spacing: 0;
    margin-bottom: 4px;
  }

  &:focus-within label {
    color: #0B20FF;
  }

  input {
    border: 1px solid #999999;
    border-radius: 3px;

    display: block;
    width: 100%;
    line-height: 25px;
    font-size: 12px;
    padding: 0 5px;

    &:focus {
      border: #1C00FF 1px solid;
      outline: none;
    }
  }
}
