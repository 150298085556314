@import './consts.scss';
@import './fonts.scss';
@import './common.scss';

.home-banner {
  height: 100vh;
  /*
  background-image: linear-gradient(
    45deg,
    $primary,
    rgb(19, 231, 231)
  );
  */
  background-image: url(../assets/bg.png);
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;

  padding: 0 $side-padding;
  padding-top: $bottom-bar-height;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  a {
    z-index: 1;
  }

  button {
    background: transparent;
    color: white;
    border: white 2px solid;

    height: calc(max(52px, #{52px / 1200px * 100vw}));
    border-radius: calc(max(26px, #{26px / 1200px * 100vw}));
    border-width: calc(max(2px, #{2px / 1200px * 100vw}));
    font-size: calc(max(14px, #{14px / 1200px * 100vw}));
    width: calc(max(230px, #{230px / 1200px * 100vw}));

    margin-top: calc(max(60px, #{60px / 1200px * 100vw}));
  }
}

.home-banner-right {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 0 100px;
}

.home-brand {
  color: white;
  font-family: 'GothamPro';
  font-weight: 400;

  font-size: calc(max(56px, #{56px / 1200px * 100vw}));
  z-index: 1;
  letter-spacing: 15px;
}

.home-slogan {
  color: white;
  font-family: 'GothamPro';
  font-weight: 200;
  font-size: calc(max(12px, #{12px / 1200px * 100vw}));
  margin-top: calc(max(20px, #{20px / 2000px * 100vw}));
  line-height: calc(max(20px, #{20px / 2000px * 100vw}));
  z-index: 1;
  letter-spacing: 1px;
}

.home-section {
  display: flex;
  margin: 20px 0;

  h2 {
    // @include header-shade($primary, 20px);
  }

  &-text {
    flex: .6;
  }

  &-img {
    flex: .4;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    border: none;
    padding: 15px 20px;
    background: $primary;
    font-size: 16px;
    font-weight: 900;

    color: white;
    // text-transform: uppercase;

    box-shadow: rgba(0,0,0,.3) 0 2px 12px;
    cursor: pointer;

    display: inline-flex;
    align-items: center;

    .material-icons-round {
      margin-left: 5px;
    }
  }
}

.img-ph {
  background: rgba(0,0,0,.1);
  height: 200px;
  width: 300px;
}

.intro {
  display: flex;
  align-items: center;
  flex-direction: column;
}

table {
  white-space: nowrap;
  text-align: center;
  width: 100%;
  padding: 10px 20px;
  border-collapse: collapse; 
  background: #FAFAFA;

  * {
    box-sizing: border-box;
  }

  thead {
    height: 40px;
    border-top: change-color(#888888, $alpha: .6) 2px solid;
    border-bottom: change-color(#888888, $alpha: .6) 1px solid;
  }

  th {
    font-family: GothamPro;
    font-weight: 900;
    font-size: 13px;
    padding: 0 20px;
  }

  tr {
    height: 55px;
    border-bottom: change-color(#888888, $alpha: .6) 1px solid;

    &.placeholder {
      border-bottom-color: transparent;
    }
  }

  td {
    font-family: GothamPro;
    font-weight: 300;
    font-size: 12px;
    color: #222222;

    text-align: center;
    white-space: pre-line;
  }

  td:first-child {
    font-weight: 800;
    padding: 0 20px;
  }

  td:last-child {
    padding: 0 20px;
  }

  .line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - #{$side-padding * 2} - 600px);
    margin: 0 auto;
  }

  .score {
    width: 85px;
    height: 25px;
    line-height: 25px;
    text-align: center;

    background: change-color(#0B20FF, $alpha: 0.15);
    border-radius: 4px;

    font-size: 13px;
    color: #0F1A92;
    font-family: GothamPro;
    font-weight: 600;

    margin: 0 auto;
  }
}

.button-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.committee {
  &-row {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }

  &-name {
    font-family: GothamPro;
    font-weight: 600;
    font-size: 21px;
    color: #111111;
    letter-spacing: -0.01px;
    margin-bottom: 8px;
  }

  &-org {
    font-family: GothamPro;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #111111;
    max-width: 160px;
  }

  &-img {
    height: 140px;
    width: 140px;
    margin-bottom: 33px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.read-more {
  color: white;

  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 40px;

  pointer-events: none;

  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @keyframes line-animation {
    0% {
      clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
    }

    25% {
      clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
    }

    50% {
      clip-path: polygon(0 100%, 0 100%, 100% 100%, 100% 100%);
    }

    100% {
      clip-path: polygon(0 100%, 0 100%, 100% 100%, 100% 100%);
    }
  }

  &-line {
    height: 50px;
    width: 2px;
    background: white;
    margin-bottom: 20px;

    // transition: clip-path 1s ease;

    // clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
    animation: line-animation 4s infinite ease;

    /*
    &.hidden {
      clip-path: polygon(0 100%, 0 100%, 100% 100%, 100% 100%);
    }
    */
  }

  &-text {
    font-family: 'GothamPro';
    font-weight: 600;
    letter-spacing: 3px;
    font-size: 14px;
  }
}


.wireframe {
  position: fixed;

  left: 0;
  top: 0;
  height: 100vh;
  right: 0;
  padding: 0 50px;

  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: space-between;

  pointer-events: none;
}

.wire {
  width: 1px;
  height: 100%;
  background: #888888;
  opacity: .1;

  .white & {
    background: white;
  }
}

.progress {
  position: fixed;

  left: 51px;
  top: 0;
  height: 100vh;
  z-index: 1000;

  &-waypoints {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -11px;
    width: 22px;
    padding: 20vh 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &-waypoint {
    height: 1px;
    width: 100%;
    background: #979797;
  }

  &-indicator {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    margin-left: -11px;
    width: 300px;
    transition: transform .2s ease;

    &-bar {
      height: 3px;
      width: 22px;
      margin-right: 10px;
      transition: background .2s ease;
      background: #0B20FF;
      border-radius: 1.5px;
    }

    &.white &-bar {
      background: white;
    }

    &-text {
      flex: 1;
      opacity: 0;
      transition: opacity .2s ease, color .2s ease;
      font-size: 11px;
      font-family: GothamPro;
      font-weight: 600;
      color: #3A3A3A;
      &.shown {
        opacity: .35;
      }
    }

    &.white &-text {
      color: white;
    }
  }
}

.ranklist {
  max-width: 1200px;
  margin: 0px auto;
  margin-top: 60px;
}

.section-spacer + .ranklist {
  margin-top: 0;
}

.multi-page {
  position: relative;

  clip-path: polygon(0% 0%, 0% 200%, 100% 200%, 100% 0);
  z-index: 200;
  max-width: 1200px;
  margin: 0 auto;
}

.multi-page-inner {
  transition: transform 1s ease;
  display: inline-block;
  width: 100%;
}

.page:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.page-inds {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.page-ind {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #636363;
  margin: 0 5px;
  cursor: pointer;

  transition: background 1s ease, border-color 1s ease;

  &-active {
    border: 1px solid transparent;
    background: #0B20FF;
    cursor: default;
  }
}

.welcome-screen {
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}

.section-spacer {
  height: 100px;
}

.submission {
  max-width: 1200px;
  margin: 0 auto;
  &-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-left {
    flex: 1;
  }

  &-right {
    flex: 1;
    transform: translateX(-10px);
  }

  &-brand {
    font-family: GothamPro;
    font-weight: 600;
    font-size: 30px;
    color: #111111;
    letter-spacing: 1px;
    line-height: 50px;
    text-shadow: 5px 0 0 #F8F8F8;
    padding-right: 50px;
  }

  &-list {
    font-size: 18px;
    color: #111111;
    letter-spacing: 0;
    line-height: 28px;
    padding-left: 30px;

    li {
      margin-bottom: 22px;
      list-style-position: inside;
      position: relative;

      &::marker {
        content: '';
      }

      &:before {
        position: absolute;
        background: #0B20FF;
        display: block;
        font-size: 24px;
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 3.5px;
        top: 28px / 2 - 7px / 2;
        left: -20px - 3.5px;
      }
    }
  }

  &-learn-more {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    color: change-color(#111111, $alpha: .5);

    img {
      width: 32px;
      height: 15px;
      margin-left: 12px;
    }
  }

  &-btn {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 10px;

    &-primary {
      border: transparent;
      background: #0B20FF;
      color: white;
    }
  }

  &-spacer {
    height: 100px;
  }
}

.hover-cell {
  position: relative;

  &-anchor {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-disp {
    pointer-events: none;
    opacity: 0;

    transition: opacity .2s ease;
    position: absolute;
    width: auto;
    height: auto;
    padding: 20px;
    background: white;
    z-index: 2;
    box-shadow: rgba(0,0,0,.3) 0 2px 12px;
    border-radius: 15px;

    transform: translateX(-50%) translateY(10px);

    font-family: GothamPro;
    font-weight: 300;
    font-size: 12px;
    color: change-color(#4A4A4A, $alpha: .6);

    text-align: center;
    white-space: pre-line;

    z-index: 400;

    &.shown {
      opacity: 1;
    }
  }

  &-portal {
    z-index: 400;
  }
}

.pager {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;

  &-num {
    margin-right: 10px;
    font-size: 12px;
    font-weight: bold;
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border: rgba(0,0,0,.1) 1px solid;
    color: #888;

    cursor: pointer;

    &-disabled {
      cursor: default;
      opacity: .25;
      z-index: -1;
    }
  }
}

.bottom-spacer {
  height: 45vh;
}

.input-mask {
  position: relative;

  &.error input {
    border: 1px solid #FC7753;
  }

  &.error input:focus {
    border: 1px solid #FC7753;
  }

  &:after {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;

    content: attr(data-error-text) " ";
    font-size: 11px;
    padding-left: 6px;
    color: #FC7753;
    display: flex;
    align-items: center;

    opacity: 0;
    pointer-events: none;
    transition: opacity .2s ease;
    background: white;
  }

  &.error:after {
    opacity: 1;
  }

  &.error:hover:after {
    opacity: 0;
  }
  &.error:focus-within:after {
    opacity: 0;
  }
}

.justify-hypens {
	hyphens: auto;
	text-align: justify;
}
