@import './common.scss';

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(0,0,0,.3);

  z-index: 1000;

  opacity: 0;
  pointer-events: none;

  transition: opacity .2s ease;

  &.shown {
    opacity: 1;
    pointer-events: all;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.dialog {
  width: 350px;
  background: white;

  box-shadow: 0 3px 10px 0 rgba(0,0,0,0.18);
  padding: 37px;
  border-radius: 18px;

  .dialog-header {
    // @include header-shade-bounded(black, 20px, 20px);
    margin-bottom: 10px;
    text-align: center;

    h2 {
      margin: 0;
      font-size: 16px;
      letter-spacing: 0;

      &:before {
        display: none;
      }
    }

    &-big h2 {
      font-size: 20px;
    }

    small {
      font-size: 18px;
    }
  }
}

.dialog-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.dialog-action {
  height: 40px;
  border-radius: 20px;
  width: 105px;
  font-size: 11px;

  &:last-child {
    margin-left: 16px;
  }

  &.primary {
    background: #0B20FF;
    color: white;
    border-color: transparent;
  }
}

.dialog-state-img {
  display: block;
  width: 85px;
  height: 85px;
  margin: 0 auto;
  margin-bottom: 35px;
}

.dialog-text {
  font-family: GothamPro;
  font-weight: 300;
  font-size: 12px;
  color: #4A4A4A;
  letter-spacing: 0;
  text-align: center;
  line-height: 21px;
}

.dialog-info {
  padding: 45px 0;
}

.dialog-action-wide {
  width: 180px;
  margin-top: 23px;

  &:last-child {
    margin-left: 0;
  }
}