@import './consts.scss';
@import './fonts.scss';
@import './common.scss';

.round-indicator {
  font-size: 16px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;

  background: rgba(0,0,0,.2);
  @include monospace;

  code {
    padding: 5px 10px;
    background: black;
    color: white;
  }

  span {
    display: inline-flex;
    padding: 0 10px;
    font-size: 48px;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.ranking-title {
  @include header-shade($primary, 20px);
  color: $primary;

  small {
    @include monospace;
    font-size: 16px;
    display: flex;
    opacity: .38;
  }

  strong {
    font-size: 48px;
    display: block;
    font-weight: 600;
    display: flex;
    align-items: center;

    span {
      font-weight: 100;
      font-size: 36px;
      padding: 0 20px 0 30px;
      height: 60px;
      line-height: 60px;
      display: inline-block;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: change-color(black, $alpha: .05);
        clip-path: polygon(10px 0, 100% 0, 100% 100%, 0 100%);

        transition: background .2s ease;
      }

      margin-left: 20px;

      cursor: pointer;

      > i {
        margin-left: 10px;
      }

      &:hover:before {
        background: change-color($primary, $alpha: .2);
      }
    }
  }
}

.ranking-nav {
  display: grid;
  grid: auto-flow / 1fr 1fr;
  grid-gap: 20px;

  h3 {
    text-decoration: underline;
  }
}

.ranking-release-indicator {
  @extend .indicator;

  margin-top: 10px;
  margin-bottom: 30px;
}

.ranking-detail-indicator {
  display: inline-flex;
  flex-direction: column;
  justify-items: center;
  font-size: 16px;
  font-weight: 100;
  color: black;

  margin-left: 20px;

  code {
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 800;
  }
}

.submit {
  background: $primary;
  color: white;
  padding: 10px 15px;
  margin-right: 20px;

  cursor: pointer;
  opacity: .87;
  transition: opacity .2s ease;

  box-shadow: rgba(0,0,0,.3) 0 2px 6px;

  &:hover {
    opacity: 1;
  }
}

.ranking-actions {
  margin-top: 60px;
  display: flex;
  align-items: center;
}

.ranking-action {
  display: inline-flex;
  align-items: center;
}

.ranking-round-sel {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  pointer-events: none;
  transition: opacity .2s ease, transform .2s ease;

  opacity: 0;
  transform: translateY(-20px);
  font-size: 20px;

  &.active {
    pointer-events: all;
    opacity: 1;
    transform: none;
  }

  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(255,255,255,.5);
  backdrop-filter: blur(4px);
  box-shadow: rgba(0,0,0,.3) 0 2px 6px;

  font-weight: 400;

  div {
    padding-left: 30px;
  }

  z-index: 1;
}