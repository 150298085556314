@import './consts.scss';

.page-enter {
  opacity: 0;
  transform: translateY($transition-dist);
}

.page-exit {
  opacity: 1;
  transform: translateY(0);
}

.page-enter-active {
  transition: opacity .2s .2s ease-out, transform .2s .2s ease-out;
  opacity: 1;
  transform: translateY(0);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: $transition-dist;
}

.page-exit-active {
  transition: opacity .2s ease-in, transform .2s ease-in;
  opacity: 0;
  transform: translateY($transition-dist);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: $transition-dist;
}