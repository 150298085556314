@import './consts.scss';
@import './fonts.scss';

@mixin header-shade($color, $dist) {
  @include header-shade-bounded($color, $dist, $side-padding)
}

@mixin header-shade-bounded($color, $dist, $bound) {
  position: relative;

  &:before {
    position: absolute;

    right: calc(100% + #{$dist});
    top: 0;
    bottom: 0;
    width: $bound;

    content: '';
    display: block;

    background-image: linear-gradient(45deg,
      transparent 25%,
      $color 25%,
      $color 50%,
      transparent 50%,
      transparent 75%,
      $color 75%
    );
    background-size: 20px 20px;
    background-repeat: repeat;
  }
}

.indicator {
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  background: rgba(0,0,0,.2);
  @include monospace;

  span {
    display: inline-flex;
    padding: 0 10px;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  code {
    padding: 5px 10px;
    background: black;
    color: white;
  }

  &.dimmed {
    code {
      background: rgba(0,0,0,.3);
    }
  }

  &.primary {
    code {
      background: $primary;
    }
  }
}


.contains-scroll-anchor {
  position: relative;
  .scroll-anchor {
    position: absolute;
    top: -$bottom-bar-height - 40px;
  }
}

button {
  height: 65px;
  width: 230px;

  font-family: GothamPro;
  font-weight: 600;
  font-size: 14px;
  color: #111111;
  letter-spacing: 1px;

  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border-radius: (65px / 2);

  transition: transform .2s ease;
  border: #3A3A3A 1px solid;

  &:hover {
    transform: scale(1.1);
  }
}

.intro {
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;


 .justify-hypens {
    text-align: justify !important;
    hyphens: auto;
}


  p {
    font-size: 16px;
    color: #111111;
    text-align: center;
    line-height: 28px;
  }
}

.title-split {
  height: 3px;
  width: 40px;

  background: black;
  margin: 0 auto 40px auto;
}


